import React, { FC } from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import { AdvancedStory } from '../../../types/stories';
import PopOver from '../PopOver';

import { AdvancedStoryCardRowControls } from './AdvancedStoryCardRowControls';

interface AdvancedStoryCardRowDropdownControlsProps {
  advancedStory: AdvancedStory;
  onRefetch?: () => void;
}
export const AdvancedStoryCardRowDropdownControls: FC<
  AdvancedStoryCardRowDropdownControlsProps
> = ({ advancedStory, onRefetch }) => {
  return (
    <PopOver
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      content={() => {
        return (
          <Box
            className="controls"
            minWidth={120}
            borderRadius="8px"
            sx={{
              '.clone-device-button, .edit-device-button, .delete-device-button':
                {
                  py: 1,
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.1)',
                  },
                },
            }}
          >
            <AdvancedStoryCardRowControls
              onRefetch={onRefetch}
              advancedStory={advancedStory}
            />
          </Box>
        );
      }}
    >
      {({ onOpen, onClose, isOpen }) => {
        const handleOpen = (e: React.MouseEvent<HTMLElement>) => {
          e.stopPropagation();
          const cb = isOpen ? onClose : onOpen;
          cb(e);
        };
        return (
          <IconButton onClick={handleOpen}>
            <MoreVertIcon />
          </IconButton>
        );
      }}
    </PopOver>
  );
};
