import { FC, useMemo } from 'react';

import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {
  calculateHoursDifference,
  formatDateWithTime,
  LocaleKeyType,
} from 'helpers/date-time';
import generateDescription from 'helpers/drsDescription';
import { useTranslation } from 'react-i18next';
import { STORIES_PATH_EDIT } from 'routes/routes';
import { DeviceReadyStory, DRSPackageStatus, StoryStatus } from 'types/stories';
import URobotIcon from 'view/icons/URobot';
import {
  black,
  black16,
  black4,
  black54,
  green,
  red,
  white,
} from 'view/theme/colors';

import eventBuss, { EventBussEvent } from '../../../eventBuss';
import { useGetDrsBackgroundImage } from '../../../hooks/useGetDrsBackgroundImage';
import { syncDeviceReadyStoryToRdmp } from '../../../services/api/stories/device-ready-stories';
import CardRowImage from '../CardRowImage';
import DeviceReadyStoryCardRowStatus from '../DeviceReadyStoryCardRowStatus';
import { NavLinkWrapper } from '../NawLinkWrapper';
import StoryStatusComponent from '../StoryStatusComponent';
import TextWithPopover from '../TextWithPopOver';

import { DeviceReadyStoryCardRowDropdownControls } from './DeviceReadyStoryCardRowDropdownControls';

type CardProps = {
  deviceReadyStory: DeviceReadyStory;
  isStatusShown?: boolean;
  hoverOff?: boolean;
  onDelete?: (id?: string) => void;
  onCopy?: (id?: string) => void;
  isBaseControlsShown?: boolean;
  controls?: React.ReactNode;
};

const DeviceReadyStoryCardRow: FC<CardProps> = ({
  deviceReadyStory,
  isStatusShown = false,
  hoverOff = false,
  onDelete,
  onCopy,
  isBaseControlsShown = true,
  controls,
}) => {
  const { t, i18n } = useTranslation();

  const titleColor = useMemo(() => {
    if (deviceReadyStory.drsPackage?.status === DRSPackageStatus.failed)
      return red;
    if (deviceReadyStory.drsPackage?.status === DRSPackageStatus.inProgress)
      return black54;
    return black;
  }, [deviceReadyStory.drsPackage?.status]);

  const bgUrl = useGetDrsBackgroundImage(deviceReadyStory);

  const handleSync = async () => {
    try {
      await syncDeviceReadyStoryToRdmp(deviceReadyStory.id);
      eventBuss.dispatch(EventBussEvent.drsPublishing);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <NavLinkWrapper
      width="100%"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      padding="20px 24px"
      borderRadius="1rem"
      border={`1px solid ${hoverOff ? black4 : 'transparent'}`}
      margin="0 0 0.5rem 0"
      bgcolor={white}
      to={`${STORIES_PATH_EDIT}/${deviceReadyStory.originalBasicStoryId}/robot/${deviceReadyStory.id}`}
      sx={{
        transition: 'transform 0.25s ease-in-out',
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: white,
          border: '1px solid rgba(0, 0, 0, 0.08)',
          boxShadow: '0 8px 24px rgba(0, 0, 0, 0.08)',
          // '.controls': {
          //   opacity: 1,
          // },
        },
        // '.controls': {
        //   transition: 'opacity 0.2s ease-in-out',
        //   opacity: 0,
        // },
      }}
    >
      <Box display="flex" alignItems="center" minWidth={0}>
        <CardRowImage
          imgUrl={bgUrl}
          title={deviceReadyStory?.title}
          sx={{ mr: 2 }}
        />
        <Box maxWidth="90%">
          <Stack mb={0.25} spacing={0.5} direction="row">
            <TextWithPopover
              variant="body1"
              color={titleColor}
              text={deviceReadyStory?.title}
            />
            {deviceReadyStory.status === StoryStatus.published &&
              calculateHoursDifference(
                new Date(),
                new Date(deviceReadyStory.updatedAt),
              ) <= 2 && (
                <Typography
                  color={green}
                  variant="body2"
                  ml={1}
                  fontWeight={700}
                >
                  NEW
                </Typography>
              )}
            {deviceReadyStory?.description && (
              <Typography color={black54}>&#x2022;</Typography>
            )}
            {deviceReadyStory.description && (
              <TextWithPopover
                color={black54}
                text={generateDescription(deviceReadyStory.description)}
              />
            )}
          </Stack>

          <Stack
            alignItems="center"
            display="flex"
            direction="row"
            sx={{ alignItems: 'center' }}
          >
            <URobotIcon color={black54} width={'1.2rem'} height={'1.2rem'} />
            <Typography variant="body2" noWrap mx={1}>
              {`${t('createdOn')} ${formatDateWithTime(
                deviceReadyStory.createdAt,
                'MMM dd, yyyy',
                i18n.language as LocaleKeyType,
              )}`}
            </Typography>
            <Typography color={black16} variant="body2" mr={1}>
              /
            </Typography>
            <Typography variant="body2" mr={1}>
              {t(`${deviceReadyStory?.storyLanguage?.toLowerCase()}`)}
            </Typography>
            {Boolean(deviceReadyStory?.version) && (
              <>
                <Typography color={black16} variant="body2" mr={1}>
                  /
                </Typography>
                <Typography variant="body2" mr={1}>
                  {`v${deviceReadyStory?.version}`}
                </Typography>
              </>
            )}
            {isStatusShown && (
              <>
                <Typography color={black16} variant="body2" mr={1}>
                  /
                </Typography>
                <StoryStatusComponent status={deviceReadyStory.status} />
              </>
            )}
          </Stack>
        </Box>
      </Box>
      <DeviceReadyStoryCardRowStatus
        drsPackageStatus={deviceReadyStory.drsPackage}
        drsStatus={deviceReadyStory.status}
        handleSync={handleSync}
      />
      {isBaseControlsShown && (
        <DeviceReadyStoryCardRowDropdownControls
          deviceReadyStory={deviceReadyStory}
          onCopy={onCopy}
          onDelete={onDelete}
        />
      )}
      {controls && <>{controls}</>}
    </NavLinkWrapper>
  );
};

export default DeviceReadyStoryCardRow;
