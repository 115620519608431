export enum EventBussEvent {
  saveDRSDraft = 'saveDRSDraft',
  openSSSidebar = 'openSSSidebar',
  openDeviceSidebar = 'openDeviceSidebar',
  importDrs = 'importDrs',
  removeDrs = 'removeDrs',
  removeSs = 'removeSs',
  cloneDrs = 'cloneDrs',
  drsPublishing = 'drsPublishing',

  importAdvancedDrs = 'importAdvancedDrs',
  removeAdvancedDrs = 'removeAdvancedDrs',
  cloneAdvancedDrs = 'cloneAdvancedDrs',
  advancedDrsPublishing = 'advancedDrsPublishing',
}

class EventBuss {
  events = new Map<EventBussEvent, ((...args: any[]) => void)[]>();

  subscribe = (
    eventName: EventBussEvent,
    callback: (...args: any[]) => void,
  ) => {
    const callbacks = this.events.get(eventName) || [];
    if (callbacks.length) {
      this.events.set(eventName, [...callbacks, callback]);
    } else {
      this.events.set(eventName, [callback]);
    }
  };

  dispatch = (eventName: EventBussEvent, ...args: any[]) => {
    const callbacks = this.events.get(eventName) || [];
    callbacks.forEach((callback: Function) => {
      callback(...args);
    });
  };

  unsubscribe = (
    eventName: EventBussEvent,
    callback: (...args: any[]) => void,
  ) => {
    const callbacks = this.events.get(eventName) || [];
    const filteredCallbacks = callbacks.filter((cb) => cb !== callback);
    this.events.set(eventName, filteredCallbacks);
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new EventBuss();
