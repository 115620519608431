import { DeviceReadyStoryContent } from '../contexts/device-ready-story/device-ready-story.type';
import { Version } from '../contexts/device-ready-story/parser/DeviceReadyStoryParser.type';

import { User } from './users';

export enum StoryStatus {
  draft = 'DRAFT',
  rejected = 'REJECTED',
  published = 'PUBLISHED',
  submitted = 'SUBMITTED',
}

export enum DRSPackageStatus {
  failed = 'FAILED',
  inProgress = 'IN_PROGRESS',
  succeeded = 'SUCCEEDED',
}

export interface DeviceReadyStory {
  id: string;
  storyId?: string;
  accountId: string;
  originalBasicStoryId: string;
  title: string;
  backgroundImage: { url: string; previewUrl: string };
  status: StoryStatus;
  tags: string[];
  backgroundAudio: {
    isPlayingAllowed?: boolean;
    url?: string;
    desiredVolumeLevel?: number;
  };
  storyLanguage: string;
  content: DeviceReadyStoryContent[];
  createdAt: Date;
  updatedAt: Date;
  contentVersion?: Version;
  creator: User;
  description?: string;
  drsPackage: null | {
    status: DRSPackageStatus;
    buildError?: string;
  };
  version?: number;
}

export interface SimpleStory {
  storyId: string;
  accountId: string;
  title: string;
  imageObjects: { previewUrl: string; url: string }[];
  drsPackages: {
    status: DRSPackageStatus;
  };
  status: StoryStatus;
  tags: string[];
  content: string;
  summary: string;
  creator: User;
  createdAt: Date;
  updatedAt: Date | null;
  denialReason?: string;
  isBookmarked?: boolean;
  minimumAge?: number;
}

export type Tag = {
  id: string;
  title: string;
};

export enum CreateDeviceReadyStoryContentControls {
  text = 'text',
  audio = 'audio',
  audioRecording = 'audioRecording',
  attachment = 'attachment',
  routines = 'routines',
}

export interface ThumbnailImage {
  url: string;
  previewUrl: string;
}

interface Creator {
  id: string;
  email: string;
  userId: string;
  imageUrl: string;
  username: string;
  roles: string[]; // or more specifically: ("STORY_COMPOSER")[]
  createdAt: string;
  updatedAt: string;
}

export interface AdvancedStory {
  id: string;
  accountId: string;
  title: string;
  description: string;
  thumbnailImage: ThumbnailImage;
  status: StoryStatus;
  tags: string[];
  storyLanguage: string;
  minimumAge: number;
  createdAt: Date;
  updatedAt: Date;
  drsPackage: null | {
    status: DRSPackageStatus;
    buildError?: string;
  };
  content: string[];
  displayContent: string[];
  creator: Creator;
}

export interface CreateAdvancedStoryParams {
  title: string;
  description: string;
  tags: string[];
  content: string[];
  displayContent: any[];
  thumbnailImage: ThumbnailImage;
  storyLanguage: string;
  minimumAge: number;
  preferredDeviceReadyStoryId: string;
}
