import React from 'react';

import { StoryTypes } from 'types/common';
import Toggle from 'view/components/ToggleButton';
import BookOpenIcon from 'view/icons/BookOpen';
import UAdvancedStoryIcon from 'view/icons/UAdvancedStory';
import URobotIcon from 'view/icons/URobot';
import { grey, purple } from 'view/theme/colors';

const StoryAndDRSToggle = ({
  setStoryTypeViewMode,
  storyTypeViewMode,
}: {
  setStoryTypeViewMode: (data: StoryTypes) => void;
  storyTypeViewMode: StoryTypes;
}) => {
  return (
    <Toggle
      sx={{ position: 'absolute', top: '16.2rem', left: 0 }}
      initialValue={storyTypeViewMode}
      options={[
        {
          value: StoryTypes.simpleStory,
          icon: (isSelected) => (
            <BookOpenIcon color={isSelected ? purple : grey} />
          ),
          onClick: () => setStoryTypeViewMode(StoryTypes.simpleStory),
        },
        {
          value: StoryTypes.deviceReadyStory,
          icon: (isSelected) => (
            <URobotIcon color={isSelected ? purple : grey} />
          ),
          onClick: () => setStoryTypeViewMode(StoryTypes.deviceReadyStory),
        },
        {
          value: StoryTypes.advancedDeviceReadyStories,
          icon: (isSelected) => (
            <UAdvancedStoryIcon color={isSelected ? purple : grey} />
          ),
          onClick: () =>
            setStoryTypeViewMode(StoryTypes.advancedDeviceReadyStories),
        },
      ]}
    />
  );
};

export default StoryAndDRSToggle;
