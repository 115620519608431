import { FC, MouseEvent } from 'react';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import SyncIcon from '@mui/icons-material/Sync';
import { Stack, StackProps } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useConfirmationDialog } from 'contexts/confirmation-dialog';
import { useTranslation } from 'react-i18next';
import { ROLES_NAMES } from 'types/users';
import EditStoryIcon from 'view/icons/EditStoryIcon';
import { black54, red, white } from 'view/theme/colors';

import Role from '../Role';

interface CardRowControlsProps {
  isEditShown?: boolean;
  isDeleteShown?: boolean;
  isCopyShown?: boolean;
  creatorId?: string;
  onEdit?: (e?: MouseEvent<HTMLElement>) => void;
  onDelete?: (e?: MouseEvent<HTMLElement>) => void;
  editStoreRoles?: ROLES_NAMES[];
  deleteStoreRoles?: ROLES_NAMES[];
  copyStoreRoles?: ROLES_NAMES[];
  onCopy?: (e?: MouseEvent<HTMLElement>) => void;
  containerProps?: StackProps;
  withText?: boolean;
  onSync?: (e?: MouseEvent<HTMLElement>) => void;
  isSyncShown?: boolean;
}

const CardRowControls: FC<CardRowControlsProps> = ({
  withText = false,
  isDeleteShown = true,
  isEditShown = true,
  isCopyShown = true,
  creatorId,
  onEdit,
  onDelete,
  editStoreRoles = [],
  deleteStoreRoles = [],
  copyStoreRoles = [],
  onCopy,
  containerProps,
  onSync,
  isSyncShown,
}) => {
  const { t } = useTranslation();
  const { openDialog } = useConfirmationDialog();

  const handleOpenDialog = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    openDialog({
      text: t('modal.deleteSs') || '',
      onConfirm: () => onDelete?.(e),
    });
  };
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="flex-end"
      spacing={2}
      {...containerProps}
    >
      {isCopyShown && (
        <Role allowedRoles={copyStoreRoles} creatorId={creatorId}>
          <Box
            display="flex"
            sx={{ cursor: 'pointer' }}
            onClick={onCopy}
            alignItems="center"
            width="100%"
            px={2}
            className="clone-device-button"
          >
            <ContentCopyIcon
              fontSize="small"
              sx={{ fill: black54, fontSize: '1.5rem' }}
            />
            <Typography ml={1} variant="body2" sx={{ color: black54 }}>
              {withText && t('hoverButtons.clone')}
            </Typography>
          </Box>
        </Role>
      )}
      {isEditShown && (
        <Role allowedRoles={editStoreRoles} creatorId={creatorId}>
          <Box
            display="flex"
            onClick={onEdit}
            sx={{ cursor: 'pointer' }}
            alignItems="center"
            width="100%"
            px={2}
            className="edit-device-button"
          >
            <EditStoryIcon sx={{ fill: white, fontSize: '1.5rem' }} />
            <Typography ml={1} variant="body2" sx={{ color: black54 }}>
              {withText && t('hoverButtons.edit')}
            </Typography>
          </Box>
        </Role>
      )}
      {isSyncShown && (
        <Role allowedRoles={editStoreRoles} creatorId={creatorId}>
          <Box
            display="flex"
            onClick={onSync}
            sx={{ cursor: 'pointer' }}
            alignItems="center"
            width="100%"
            px={2}
            className="edit-device-button"
          >
            <SyncIcon sx={{ fill: black54, fontSize: '1.5rem' }} />
            <Typography ml={1} variant="body2" sx={{ color: black54 }}>
              {withText && t('hoverButtons.sync')}
            </Typography>
          </Box>
        </Role>
      )}
      {isDeleteShown && (
        <Role allowedRoles={deleteStoreRoles} creatorId={creatorId}>
          <Box
            display="flex"
            onClick={handleOpenDialog}
            sx={{ cursor: 'pointer' }}
            alignItems="center"
            width="100%"
            px={2}
            className="delete-device-button"
          >
            <DeleteOutlineIcon sx={{ fill: red, fontSize: '1.5rem' }} />
            <Typography ml={1} variant="body2" sx={{ color: red }}>
              {withText && t('hoverButtons.delete')}
            </Typography>
          </Box>
        </Role>
      )}
    </Stack>
  );
};

export default CardRowControls;
