import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import {
  LIBRARY_PATH_ALL,
  LIBRARY_PATH_BOOKMARKS,
  LIBRARY_PATH_DRAFTS,
  LIBRARY_PATH_PUBLISHED,
  LIBRARY_PATH_REJECTED,
  LIBRARY_PATH_SUBMITTED,
} from 'routes/routes';
import { getLibraryStats as getAdvLibraryStats } from 'services/api/stories/advanced-stories';
import { getLibraryStats as getDrsLibraryStats } from 'services/api/stories/device-ready-stories';
import { getLibraryStats } from 'services/api/stories/simple-stories';
import { StoryTypes } from 'types/common';
import CategoriesTabs from 'view/components/CategoriesTabs';

import { EventBussEvent } from '../../../../eventBuss';
import { useSubscribeEventBuss } from '../../../../eventBuss/useSubscribeEventBuss';

interface TabsProps {
  storyTypeViewMode: StoryTypes;
}

export default function Tabs({ storyTypeViewMode }: TabsProps) {
  const { t } = useTranslation();
  const [libraryStats, setLibraryStats] = useState({
    [StoryTypes.simpleStory]: {
      published: 0,
      submitted: 0,
      rejected: 0,
      draft: 0,
      bookmarks: 0,
    },
    [StoryTypes.deviceReadyStory]: {
      published: 0,
      submitted: 0,
      rejected: 0,
      draft: 0,
      bookmarks: 0,
    },
    [StoryTypes.advancedDeviceReadyStories]: {
      published: 0,
      submitted: 0,
      rejected: 0,
      draft: 0,
      bookmarks: 0,
    },
  });

  const getSSLibraryStats = useCallback(async () => {
    const data = await getLibraryStats();
    setLibraryStats((prev) => ({
      ...prev,
      [StoryTypes.simpleStory]: data.stories,
    }));
  }, []);

  const getDRSLibraryStats = useCallback(async () => {
    const data = await getDrsLibraryStats();
    setLibraryStats((prev) => ({
      ...prev,
      [StoryTypes.deviceReadyStory]: data.deviceReadyStories,
    }));
  }, []);

  const getAdvancedLibraryStats = useCallback(async () => {
    const data = await getAdvLibraryStats();

    setLibraryStats((prev) => ({
      ...prev,
      [StoryTypes.advancedDeviceReadyStories]: data.advancedDeviceReadyStories,
    }));
  }, []);

  useEffect(() => {
    getSSLibraryStats();
    getDRSLibraryStats();
    getAdvancedLibraryStats();
  }, [getAdvancedLibraryStats, getDRSLibraryStats, getSSLibraryStats]);

  useSubscribeEventBuss(EventBussEvent.importDrs, getDRSLibraryStats);
  useSubscribeEventBuss(EventBussEvent.removeDrs, getDRSLibraryStats);
  useSubscribeEventBuss(EventBussEvent.cloneDrs, getDRSLibraryStats);
  useSubscribeEventBuss(EventBussEvent.removeSs, getSSLibraryStats);

  useSubscribeEventBuss(
    EventBussEvent.importAdvancedDrs,
    getAdvancedLibraryStats,
  );
  useSubscribeEventBuss(
    EventBussEvent.removeAdvancedDrs,
    getAdvancedLibraryStats,
  );
  useSubscribeEventBuss(
    EventBussEvent.cloneAdvancedDrs,
    getAdvancedLibraryStats,
  );
  useSubscribeEventBuss(
    EventBussEvent.advancedDrsPublishing,
    getAdvancedLibraryStats,
  );

  const { published, submitted, rejected, draft, bookmarks } =
    libraryStats[storyTypeViewMode];

  const tabs = useMemo(
    () => [
      {
        title: t('library.tabs.all'),
        link: LIBRARY_PATH_ALL,
        count: published + submitted + rejected + draft,
      },
      {
        title: t('library.tabs.published'),
        link: LIBRARY_PATH_PUBLISHED,
        count: published,
      },
      {
        title: t('library.tabs.submitted'),
        link: LIBRARY_PATH_SUBMITTED,
        count: submitted,
      },
      {
        title: t('library.tabs.rejected'),
        link: LIBRARY_PATH_REJECTED,
        count: rejected,
      },
      {
        title: t('library.tabs.draft'),
        link: LIBRARY_PATH_DRAFTS,
        count: draft,
      },
      {
        title: t('library.tabs.bookmarks'),
        link: LIBRARY_PATH_BOOKMARKS,
        count: bookmarks,
      },
    ],
    [bookmarks, draft, published, rejected, submitted, t],
  );

  return <CategoriesTabs tabs={tabs} />;
}
