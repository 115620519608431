import { FC } from 'react';

import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import eventBuss, { EventBussEvent } from '../../../eventBuss';
import { formatDateWithTime, LocaleKeyType } from '../../../helpers/date-time';
import { STORIES_ADVANCED_VIEW } from '../../../routes/routes';
import { syncAdvancedStory } from '../../../services/api/stories/advanced-stories';
import { AdvancedStory } from '../../../types/stories';
import URobotIcon from '../../icons/URobot';
import { black16, black54, white } from '../../theme/colors';
import CardRowImage from '../CardRowImage';
import DeviceReadyStoryCardRowStatus from '../DeviceReadyStoryCardRowStatus';
import { NavLinkWrapper } from '../NawLinkWrapper';
import TextWithPopover from '../TextWithPopOver';

import { AdvancedStoryCardRowDropdownControls } from './AdvancedStoryCardRowDropdownControls';

interface AdvancedStoryCardRowProps {
  advancedStory: AdvancedStory;
  onRefetch?: () => void;
}

const AdvancedStoryCardRow: FC<AdvancedStoryCardRowProps> = ({
  advancedStory,
  onRefetch,
}) => {
  const previewUrl = advancedStory?.thumbnailImage?.previewUrl || '';

  const { t, i18n } = useTranslation();
  const handleSync = async () => {
    try {
      await syncAdvancedStory(advancedStory.id);
      eventBuss.dispatch(EventBussEvent.drsPublishing);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <NavLinkWrapper
      width="100%"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      padding="20px 24px"
      borderRadius="1rem"
      margin="0 0 0.5rem 0"
      border={`1px solid transparent`}
      bgcolor={white}
      to={`${STORIES_ADVANCED_VIEW}/${advancedStory.id}`}
      sx={{
        transition: 'transform 0.25s ease-in-out',
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: white,
          border: '1px solid rgba(0, 0, 0, 0.08)',
          boxShadow: '0 8px 24px rgba(0, 0, 0, 0.08)',
        },
      }}
    >
      <Box display="flex" alignItems="center" minWidth={0}>
        <CardRowImage
          imgUrl={previewUrl}
          title={advancedStory?.title}
          sx={{ mr: 2 }}
        />
        <Box maxWidth="90%">
          <Stack mb={0.25} spacing={0.5} direction="row">
            <TextWithPopover variant="body1" text={advancedStory?.title} />
          </Stack>
          <Stack
            alignItems="center"
            display="flex"
            direction="row"
            sx={{ alignItems: 'center' }}
          >
            <URobotIcon color={black54} width={'1.2rem'} height={'1.2rem'} />
            <Typography variant="body2" noWrap mx={1}>
              {`${t('createdOn')} ${formatDateWithTime(
                advancedStory.createdAt,
                'MMM dd, yyyy',
                i18n.language as LocaleKeyType,
              )}`}
            </Typography>
            <Typography color={black16} variant="body2" mr={1}>
              /
            </Typography>
            <Typography variant="body2" mr={1}>
              {t(`${advancedStory?.storyLanguage?.toLowerCase()}`)}
            </Typography>
            {/*{Boolean(advancedStory?.version) && (*/}
            {/*  <>*/}
            {/*    <Typography color={black16} variant="body2" mr={1}>*/}
            {/*      /*/}
            {/*    </Typography>*/}
            {/*    <Typography variant="body2" mr={1}>*/}
            {/*      {`v${advancedStory?.version}`}*/}
            {/*    </Typography>*/}
            {/*  </>*/}
            {/*)}*/}
          </Stack>
        </Box>
      </Box>
      <DeviceReadyStoryCardRowStatus
        drsPackageStatus={advancedStory.drsPackage}
        drsStatus={advancedStory.status}
        handleSync={handleSync}
      />
      <AdvancedStoryCardRowDropdownControls
        onRefetch={onRefetch}
        advancedStory={advancedStory}
      />
    </NavLinkWrapper>
  );
};

export default AdvancedStoryCardRow;
