export enum URL {
  DEVICE_READY_STORIES = '/device-ready-stories',
  DEVICE_PUBLISHED_READY_STORIES = '/device-ready-stories/published',
  DEVICE_READY_STORIES_SEARCH = '/device-ready-stories/search',
  ADVANCED_STORIES_SEARCH = '/advanced-device-ready-stories/search',
  ADVANCED_STORIES = '/advanced-device-ready-stories',
  SIMPLE_PUBLISHED_STORIES = '/stories/published',
  SIMPLE_PUBLISHED_STORIES_STATS = '/stories/stats',
  SIMPLE_STORIES = '/stories/search',
  STORIES = '/stories',
  STORIES_PERSONAL = '/stories/personal',
  SIMPLE_STORIES_LIBRARY_STATS = '/stories/library-stats',
  DEVICE_READY_STORIES_LIBRARY_STATS = 'device-ready-stories/library-stats',
  ADVANCED_STORIES_LIBRARY_STATS = 'advanced-device-ready-stories/library-stats',
  TAGS = '/tags',
  ACCOUNTS = '/accounts',
  ACCOUNTS_INVITE = '/accounts/invite',
  BOOKMARKS_STORIES = '/bookmarks/stories',
  MODERATION_STORIES = '/moderation/stories',
  MODERATION_DEVICE_READY_STORIES = '/moderation/device-ready-stories',
  MODERATION_ADVANCED_STORIES = '/moderation/advanced-device-ready-stories',

  DEVICES = '/devices',
  DEVICES_BULK_DELETE = '/devices/bulkDelete',
  AVAILABLE_HARU_ROUTINES = '/devices/haru/available-routines',

  DRS_COMMENTS_GET = 'commenting/device-ready-stories',
  DRS_COMMENTS = 'commenting/device-ready-stories/comments',

  SS_COMMENTS_GET = 'commenting/stories',
  SS_COMMENTS = 'commenting/stories/comments',
}
