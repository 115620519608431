import { FC, MouseEvent } from 'react';

import { useNavigate } from 'react-router-dom';

import { STORIES_ADVANCED_VIEW } from '../../../routes/routes';
import { deleteAdvancedStory } from '../../../services/api/stories/advanced-stories';
import { AdvancedStory } from '../../../types/stories';
import { ROLES_NAMES } from '../../../types/users';
import CardRowControls from '../CardRowControls';

interface AdvancedStoryCardRowControlsProps {
  onRefetch?: () => void;
  advancedStory: AdvancedStory;
}

export const AdvancedStoryCardRowControls: FC<
  AdvancedStoryCardRowControlsProps
> = ({ onRefetch, advancedStory }) => {
  const navigate = useNavigate();
  const onEdit = (e?: MouseEvent<HTMLElement>) => {
    e?.stopPropagation();
    navigate(`${STORIES_ADVANCED_VIEW}/${advancedStory.id}?edit=true`);
  };
  const onDelete = async (e?: MouseEvent<HTMLElement>) => {
    e?.stopPropagation();
    await deleteAdvancedStory(advancedStory.id);
    onRefetch && onRefetch();
  };
  return (
    <CardRowControls
      withText
      isCopyShown={false}
      onEdit={onEdit}
      onDelete={onDelete}
      editStoreRoles={[ROLES_NAMES.storyCreator]}
      deleteStoreRoles={[ROLES_NAMES.storyCreator]}
      containerProps={{
        direction: 'column',
        spacing: 1,
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '100%',
      }}
    />
  );
};
