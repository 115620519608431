import {
  AdvancedStory,
  CreateAdvancedStoryParams,
  DeviceReadyStory,
} from '../../../types/stories';
import { request } from '../request';
import { URL } from '../url';

import { generateParams } from './api-stories.utils';

import type {
  ApiGetListParams,
  LibraryStats,
  PaginatedResponse,
} from '../types';

export const searchAdvancedStories = async (
  params: ApiGetListParams,
): Promise<PaginatedResponse<DeviceReadyStory>> => {
  const { data } = await request({
    url: URL.ADVANCED_STORIES_SEARCH,
    params: generateParams(params),
  });
  return data;
};

export const createAdvancedStory = async (
  params: CreateAdvancedStoryParams,
): Promise<AdvancedStory> => {
  const { data } = await request({
    url: URL.ADVANCED_STORIES,
    method: 'POST',
    data: params,
  });

  return data;
};

export const submitAdvancedStory = async (id: string) =>
  request({
    url: URL.ADVANCED_STORIES + `/${id}/submit`,
    method: 'PATCH',
  });

export const getAdvancedStory = async (id: string): Promise<AdvancedStory> => {
  const { data } = await request({
    url: URL.ADVANCED_STORIES + `/${id}`,
    method: 'GET',
  });
  return data;
};

export const updateAdvancedStory = async (
  id: string,
  params: CreateAdvancedStoryParams,
) =>
  request({
    url: URL.ADVANCED_STORIES + `/${id}`,
    method: 'PATCH',
    data: params,
  });

export const deleteAdvancedStory = async (id: string) =>
  request({
    url: URL.ADVANCED_STORIES + `/${id}`,
    method: 'DELETE',
  });

export const syncAdvancedStory = async (id: string) => {
  const { data } = await request({
    url: URL.ADVANCED_STORIES + `/${id}/sync-to-rdmp`,
    method: 'POST',
  });
  return data;
};

export const approveAdvancedStory = async (id: string) =>
  request({
    url: URL.MODERATION_ADVANCED_STORIES + `/${id}/approve`,
    method: 'PUT',
  });

export const getLibraryStats = async (): Promise<{
  advancedDeviceReadyStories: LibraryStats;
}> => {
  const { data } = await request({
    url: URL.ADVANCED_STORIES_LIBRARY_STATS,
  });
  return data;
};
