import React, { forwardRef, ReactNode } from 'react';

import { FormHelperText, SxProps, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { black16, black54, black87, red } from 'view/theme/colors';

type FormInputProps = {
  id?: string;
  label?: string | null;
  name?: string;
  placeholder?: string;
  value?: string | ReactNode;
  error?: boolean;
  select?: boolean;
  inputProps?: object;
  helperText?: React.ReactElement | string;
  variant?: 'filled' | 'outlined';
  type?: React.InputHTMLAttributes<unknown>['type'];
  fullWidth?: boolean;
  disabled?: boolean;
  startAdornment?: React.ReactElement;
  endAdornment?: React.ReactElement;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  inputRef?: React.Ref<any>;
  small?: boolean;
  sx?: SxProps<Theme>;
};

const Input = forwardRef<any, FormInputProps>(
  (
    {
      variant = 'outlined',
      inputProps,
      label,
      id,
      name,
      value,
      error,
      helperText,
      placeholder,
      type,
      disabled,
      fullWidth = true,
      startAdornment,
      endAdornment,
      onChange,
      inputRef,
      small,
      select = false,
      sx = {},
      ...props
    },
    ref,
  ) => {
    return (
      <FormControl fullWidth error={error} sx={{ position: 'relative' }}>
        <TextField
          id={id}
          name={name ? name : id}
          type={type}
          variant={variant}
          inputProps={inputProps}
          margin="none"
          select={select}
          fullWidth={fullWidth}
          disabled={disabled}
          placeholder={placeholder}
          label={label}
          error={error}
          value={value}
          onChange={onChange}
          inputRef={inputRef}
          size={small ? 'small' : undefined}
          ref={ref}
          sx={{
            ...sx,
            '& .MuiInputBase-root': { height: small ? '3rem' : '3.5rem' },
            '& .MuiInputLabel-root': {
              fontSize: '0.875rem',
              lineHeight: '24px',
              fontWeight: 500,
              color: error ? `${red}` : `${black54}`,
              '&.Mui-focused': {
                color: error ? `${red}` : `${black54}`,
              },
            },
            '& .MuiOutlinedInput-root': {
              borderRadius: '0.5rem',
              color: `${black87}`,
              borderColor: error ? red : black16,
              '&.Mui-focused': {
                borderColor: `${red}`,
                '& fieldset': {
                  border: `1px solid ${error ? red : black16}`,
                },
              },
            },
            '& .MuiInputBase-input::placeholder': {
              color: error ? `${red}` : `${black54}`,
              fontWeight: 500,
            },
          }}
          InputProps={{
            autoComplete: 'off',
            startAdornment: startAdornment ? (
              <InputAdornment position="start">{startAdornment}</InputAdornment>
            ) : undefined,
            endAdornment: endAdornment ? (
              <InputAdornment position="end">{endAdornment}</InputAdornment>
            ) : undefined,
          }}
          {...props}
        />
        {!!helperText && (
          <Box>
            <FormHelperText>{helperText}</FormHelperText>
          </Box>
        )}
      </FormControl>
    );
  },
);

export default Input;
