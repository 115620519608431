import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Divider } from '@mui/material';
import Box from '@mui/material/Box';
import { EventBussEvent } from 'eventBuss';
import { useSubscribeEventBuss } from 'eventBuss/useSubscribeEventBuss';
import useFetchListDataWithFilteringAndSorting from 'hooks/useFetchListDataWithFilteringAndSorting';
import useGetAttributeAndNavigate from 'hooks/useGetAttributeAndNavigate';
import useRefetchIfDrsPublishing from 'hooks/useRefetchIfDrsPublishing';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import StickyBox from 'react-sticky-box';
import { searchDeviceReadyStories } from 'services/api/stories/device-ready-stories';
import {
  searchBookmarkStories,
  searchStories,
} from 'services/api/stories/simple-stories';
import { ApiGetListParams } from 'services/api/types';
import { LibraryItemTypes, StoryTypes } from 'types/common';
import { AdvancedStory, DeviceReadyStory, SimpleStory } from 'types/stories';
import { ROLES_NAMES } from 'types/users';
import { LIBRARY_DATA_TEST_ID_PREFIX } from 'view/components/CategoriesTabs';
import DeviceReadyStoryCardRow from 'view/components/DeviceReadyStoryCardRow';
import DevicesDrawer from 'view/components/DevicesDrawer';
import FilterSortBlock from 'view/components/FilterSortBlock/FilterSortBlock';
import MainContainer from 'view/components/MainContainer';
import MainLayout from 'view/components/MainLayout';
import NoSearchResultsWrapper from 'view/components/NoSearchResultsWrapper';
import Role from 'view/components/Role';
import SimpleStoryCardRow from 'view/components/SimpleStoryCardRow';
import TablePagination from 'view/components/TablePagination';
import { lightGray } from 'view/theme/colors';

import { useSynchedFilters } from '../../../../hooks/useSynchedFilters';
import { searchAdvancedStories } from '../../../../services/api/stories/advanced-stories';
import AdvancedStoryCardRow from '../../../components/AdvancedStoryCardRow';
import SimpleStoriesDrawer from '../../home/components/SimpleStoriesDrawer';
import StoryAndDRSToggle from '../components/StoryAndDRSToggle';
import Tabs from '../components/Tabs';

export const VIEW_MODE_STORE_KEY = 'storyTypeViewMode';

export default function LibraryList() {
  const { t } = useTranslation();

  useGetAttributeAndNavigate(LIBRARY_DATA_TEST_ID_PREFIX);
  const { type = '' } = useParams();

  const [storyTypeViewMode, setStoryTypeViewMode] = useState<StoryTypes>(() => {
    const mode = sessionStorage.getItem(VIEW_MODE_STORE_KEY) as StoryTypes;
    return mode || StoryTypes.simpleStory;
  });

  const searchItems = useMemo(() => {
    if (type === LibraryItemTypes.bookmarks) return searchBookmarkStories;

    if (storyTypeViewMode === StoryTypes.simpleStory) return searchStories;

    if (storyTypeViewMode === StoryTypes.advancedDeviceReadyStories)
      return searchAdvancedStories;

    return searchDeviceReadyStories;
  }, [storyTypeViewMode, type]);

  const handleFetchItems = useCallback(
    (params: ApiGetListParams) => {
      if (
        !params.status ||
        (typeof params.status === 'string' &&
          type !== params.status.toLowerCase())
      )
        return;
      if (type === LibraryItemTypes.bookmarks) {
        return searchItems({ ...params, status: undefined });
      }
      return searchItems(params);
    },
    [searchItems, type],
  );

  const {
    data,
    onChangePage,
    sorting,
    onSetSorting,
    onSetTags,
    onSetSearchQuery,
    onSetStatus,
    onRefetch,
    fetchLoading,
    isFiltersApplied,
    handleUpdatePageQuery,
    setIsSynced,
  } = useFetchListDataWithFilteringAndSorting<
    SimpleStory | DeviceReadyStory | AdvancedStory
  >(handleFetchItems);

  const {
    synchedFilters,
    onClearSynchedFilters,
    onApplySynchedFilters,
    setSynchedFilters,
  } = useSynchedFilters(setIsSynced);

  const handleSetViewMode = useCallback(
    (mode: StoryTypes) => {
      handleUpdatePageQuery(1);
      setStoryTypeViewMode(mode);
      sessionStorage.setItem(VIEW_MODE_STORE_KEY, mode);
      if (mode === StoryTypes.simpleStory) {
        setIsSynced(undefined);
        onClearSynchedFilters();
      }
    },
    [handleUpdatePageQuery, onClearSynchedFilters, setIsSynced],
  );

  useEffect(() => {
    onSetStatus(type.toUpperCase());
    if (type === LibraryItemTypes.bookmarks) {
      setStoryTypeViewMode(StoryTypes.simpleStory);
      sessionStorage.setItem(VIEW_MODE_STORE_KEY, StoryTypes.simpleStory);
    }
  }, [type, onSetStatus, handleSetViewMode]);

  useSubscribeEventBuss(EventBussEvent.importDrs, onRefetch);

  const items = data?.items || [];

  useRefetchIfDrsPublishing(
    storyTypeViewMode === StoryTypes.deviceReadyStory ||
      storyTypeViewMode === StoryTypes.advancedDeviceReadyStories
      ? (items as DeviceReadyStory[] | AdvancedStory[])
      : [],
    onRefetch,
  );

  return (
    <MainContainer component="main">
      <DevicesDrawer />
      <SimpleStoriesDrawer />
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        alignItems="center"
      >
        <Role
          allowedRoles={[
            ROLES_NAMES.moderator,
            ROLES_NAMES.storyComposer,
            ROLES_NAMES.robotHandler,
          ]}
        >
          {type !== LibraryItemTypes.bookmarks && (
            <StoryAndDRSToggle
              setStoryTypeViewMode={handleSetViewMode}
              storyTypeViewMode={storyTypeViewMode}
            />
          )}
        </Role>
        <MainLayout>
          <Tabs storyTypeViewMode={storyTypeViewMode} />
        </MainLayout>
        <Divider sx={{ width: '100%' }} />
        <MainLayout>
          <FilterSortBlock
            setTags={onSetTags}
            setSearchQuery={onSetSearchQuery}
            sorting={sorting}
            setSorting={onSetSorting}
            customFilters={
              storyTypeViewMode === StoryTypes.deviceReadyStory
                ? synchedFilters
                : undefined
            }
            setCustomFilters={setSynchedFilters}
            onCleanCustomFilters={onClearSynchedFilters}
            onApplyCustomFilters={onApplySynchedFilters}
            customFiltersTitle={t('filtering.byStatus') || ''}
          />
        </MainLayout>
      </Box>
      <Box
        width="100%"
        height="100%"
        sx={{ backgroundColor: lightGray }}
        pt={4}
      >
        <MainLayout display="flex" flexDirection="column" height="100%">
          <Box
            sx={{ flexDirection: 'column', flexWrap: 'wrap' }}
            display="flex"
            height="100%"
          >
            <NoSearchResultsWrapper
              isLoading={fetchLoading}
              title={type}
              isFiltersApplied={isFiltersApplied}
            >
              {items.map((item) => {
                if (storyTypeViewMode === StoryTypes.simpleStory) {
                  const simpleStoryItem = item as SimpleStory;
                  return (
                    <SimpleStoryCardRow
                      story={simpleStoryItem}
                      onDelete={onRefetch}
                      key={simpleStoryItem.storyId}
                    />
                  );
                }
                if (
                  storyTypeViewMode === StoryTypes.advancedDeviceReadyStories
                ) {
                  const advancedStory = item as AdvancedStory;
                  return (
                    <AdvancedStoryCardRow
                      advancedStory={advancedStory}
                      onRefetch={onRefetch}
                      key={advancedStory.id}
                    />
                  );
                }
                const deviceReadyStory = item as DeviceReadyStory;
                return (
                  <DeviceReadyStoryCardRow
                    deviceReadyStory={deviceReadyStory}
                    onDelete={onRefetch}
                    onCopy={onRefetch}
                    key={deviceReadyStory.id}
                    isStatusShown
                  />
                );
              })}
            </NoSearchResultsWrapper>
          </Box>
        </MainLayout>
        <StickyBox bottom style={{ backgroundColor: 'white', zIndex: 10 }}>
          <MainLayout>
            <TablePagination {...data?.meta} onPageChange={onChangePage} />
          </MainLayout>
        </StickyBox>
      </Box>
    </MainContainer>
  );
}
